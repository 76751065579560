






import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Logout extends Vue {
  private created(): void {
    window.sessionStorage.clear();
    // Redirect to front page
    window.location.href = '/';
  }
}
